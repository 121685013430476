import React from 'react';
import styled from 'styled-components';
import { Flex, Text } from '../../components/Ui';

function Progress({ percent }) {
  return (
    <Container percent={percent}>
      <Flex gap={16} align='flex-end' justify='space-between'>
        <Text bold size={24}>
          Completamento
        </Text>
        <Text bold size={24} primary align={'right'}>
          {percent}%
        </Text>
      </Flex>

      <div className='progress'>
        <div className='progress-track'></div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  .progress {
    margin-top: 16px;
    margin-bottom: 16px;
    height: 18px;
    border-radius: 18px;
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;

    &-track {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: inherit;
      background-color: ${({ theme }) => theme.primary};
      width: ${({ percent }) => percent}%;
    }
  }
`;

export default Progress;
