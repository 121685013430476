import React from 'react';
import { ReactComponent as Upload } from '../../assets/images/upload.svg';
import { ReactComponent as Watch } from '../../assets/images/watch.svg';
import background from '../../assets/images/aprileBanner.png';
import { Box, Button, Flex, Text } from '../../components/Ui';
import Layout from './Layout';
import Progress from './Progress';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

function Aprile2025() {
  const percentage = useSelector(state => state.user.user?.abb_missions?.welcome_bonus_plus);
  const userType = useSelector(state => state.user.user?.userinfo?.type);

  return (
    <Layout banner={background}>
      <Box shadow className='mt-15'>
        <Text bold size={30}>
          {userType === 'installatore'
            ? 'Con i prodotti Welcome M, guadagni più punti!'
            : 'Con i prodotti Welcome M, si guadagnano più punti!'}
        </Text>
        <Text className='mt-12'>
          {userType === 'installatore'
            ? 'Nel periodo che va dal 7 aprile 2025 al 19 maggio 2025, acquista i prodotti “Welcome M: Soluzioni per impianti di citofonia e videocitofonia” e carica i tuoi DDT/Fatture per maturare 2 punti ogni 2€ di listino invece di 1 punto ogni 2€.'
            : `Nel periodo che va dal 7 aprile 2025 al 19 maggio 20255, ricorda ai tuoi clienti che acquistando i prodotti della classe commerciale BLD10B20 “Welcome M: Soluzioni per impianti di citofonia e videocitofonia” e caricando i DDT/Fatture validi maturano 2 punti ogni 2€ di listino invece di 1 punto ogni 2€. <br/><br/>È composto da almeno tre dispositivi: un posto esterno, un alimentatore di sistema e un posto interno.  Per i dispositivi non connessi, con un Ip- gateway aggiuntivo, il sistema può essere collegato a un cellulare, in modo che le chiamate provenienti dal posto esterno vengano inoltrate ai telefoni collegati. Per tutti gli altri dispositivi es.monitor wi-fi , One touch 7’ e Smart touch 10’ non serve nessun dispositivo aggiuntivo, è sufficiente la rete internet.
            <br/>
            Il sistema può essere controllato tramite:<br/>
            - Posto esterno per attivare la chiamata all’ingresso di casa<br/>
             - Postazione interna per controllare chi è alla porta o parlare con i tuoi ospiti<br/>
             - App per accedere da remoto alla tua porta di casa in qualsiasi momento<br/>
             - Display che integra la tua citofonia nel tuo sistema Free@home e avere un punto di controllo centralizzato<br/><br/>
             Qui trovi il riassunto dei DDT che sono stati caricati con il tuo codice PLUS.`}
        </Text>
        {userType === 'installatore' ? (
          <Link to='/load-ddt'>
            <Button active className='mt-24' upper>
              CARICA DDT/FATTURE
              <Upload />
            </Button>
          </Link>
        ) : (
          <Link to='/user/profile'>
            <Button active className='mt-24' upper>
              DDT/FATTURE A TE ASSOCIATI
            </Button>
          </Link>
        )}
      </Box>
    </Layout>
  );
}

export default Aprile2025;
