export const signinform = (ref, inModal) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email aziendale',
        ref,
      },
      icon: '',
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      icon: 'password',
      label: 'Password',
      value: '',
      showPwd: true,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
  };
};

export const registrationform = (
  email,
  firstname,
  lastname,
  username,
  company,
  company_role,
  psw,
  pswrepeat,
  dateofbirth,
  sex,
  male,
  female,
  pswerror,
  pswconfirmerror,
  ref,
  gdpr,
  gdprAccept,
  gdprReject,
  fields_required,
) => {
  return {
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome*',
      },
      label: `${firstname}`,
      errorMessage: 'CAMPO OBBLIGATORIO',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: 'Cognome*',
      },
      label: `${lastname}`,
      value: '',
      errorMessage: 'CAMPO OBBLIGATORIO',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Numero di telefono aziendale*',
      },
      label: '',
      value: '',
      errorMessage: 'CAMPO OBBLIGATORIO',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email aziendale*',
      },
      icon: '',
      label: `${email}`,
      errorMessage: 'CAMPO OBBLIGATORIO',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    repeatemail: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'repeatemail',
        id: 'repeatemail',
        placeholder: 'Conferma email*',
      },
      icon: '',
      errorMessage: 'CAMPO OBBLIGATORIO',
      label: ``,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    company: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'company',
        id: 'company',
        placeholder: `Ragione sociale*`,
      },
      errorMessage: 'CAMPO OBBLIGATORIO',
      hint: {
        field: 'Ragione sociale',
        hint: `Nome che identifica la società seguito dall’acronimo del tipo di società.`,
      },
      label: `${company}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    country: {
      elementType: 'select',
      isCountry: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Stato*',
            disabled: true,
          },
        ],
        placeholder: 'Stato*',
        name: 'country',
      },
      label: '',
      errorMessage: 'CAMPO OBBLIGATORIO',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Provincia*',
            disabled: true,
          },
        ],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Città*',
            disabled: true,
          },
        ],
        placeholder: 'Città',
        name: 'city',
      },
      label: 'Città',
      value: ``,
      validation: {
        required: fields_required.find(item => item === 'city') ? true : false,
        touched: false,
      },
      valid: fields_required.find(item => item === 'city') ? false : true,
    },
    vatcode: {
      elementType: 'input',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'text',
        name: 'vatcode',
        id: 'vatcode',
        placeholder: 'P.IVA*',
      },
      hint: {
        field: 'Partita IVA',
        hint: `Serie di 11 numeri che identifica il tuo locale es:00112233445`,
      },
      icon: '',
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    how_know: {
      elementType: 'select',
      isHowKnow: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Come hai saputo di questa iniziativa',
            disabled: true,
          },
        ],
        placeholder: 'Come hai saputo di questa iniziativa',
        name: 'how_know',
      },
      label: 'Come hai saputo di questa iniziativa',
      value: ``,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    password: {
      elementType: 'input',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      icon: 'password',
      label: '',
      value: '',
      showPwd: true,
      hintPwd:
        'La password deve essere composta da minimo 8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&)',

      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },

      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: 'Conferma password',
      },
      icon: 'password',
      label: `${pswrepeat}`,
      value: '',
      showPwd: true,
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },

    plus_code: {
      elementType: 'input',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'text',
        name: 'plus_code',
        id: 'plus_code',
        placeholder: `Codice PLUS`,
      },
      hint: {
        field: 'Codice PLUS',
        hint: `Codice di 6 elementi (cifre e lettere) associati al Distributore di materiale elettrico che ti serve solitamente o che ti ha informato dell’iniziativa.`,
      },
      label: '',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    mgm: {
      elementType: 'input',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'text',
        name: 'mgm',
        id: 'mgm',
        placeholder: `Codice amico`,
      },
      hint: {
        field: 'Codice amico',
        hint: `Un collega ti ha invitato ad iscriverti? Inserisci qui il suo codice amico ed entrambi otterrete punti extra.`,
      },
      label: '',
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },

    accetta_regolamento: {
      elementType: 'inputcheckbox',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento',
        id: 'accetta_regolamento',
      },
      label: `<p>Dichiaro di aver letto integralmente e di accettare il  <a href="https://abbpremiplus.it/app/MODIFICA_Regolamento_OP_ABB_Premi_Plus_2024_2025.pdf" target="_blank">Regolamento</a> e l’<a href="https://abbpremiplus.it/app/privacy-policy.html" target="_blank">Informativa Privacy* dell'operazione a premi ABB Premi Plus 2024/2025</a></p>`,
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    accetta_regolamento_concorso_25: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento_concorso_25',
        id: 'accetta_regolamento_concorso_25',
      },
      errorMessage: 'CAMPO OBBLIGATORIO',
      label: `<p>Dichiaro di aver letto integralmente e di accettare il <a href="https://abbpremiplus.it/app/2025_CONCORSO_ABB_2025_Installatori_e_Banconisti.pdf" target="_blank"><strong>Regolamento del concorso ABB Premi Plus 2025</strong></a>.</p>`,
      value: 0,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    gdpr_marketing: {
      elementType: 'inputcheckbox',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label: `<p>Dichiaro di prestare il consenso al trattamento dei miei dati personali per le finalità di invio tramite e-mail di comunicazioni commerciali e/o di materiale pubblicitario, relativo a prodotti e/o servizi</p>`,
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};

export const registrationformDistributer = () => {
  return {
    country: {
      elementType: 'select',
      isCountry: true,
      isDistributer: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Stato*',
            disabled: true,
          },
        ],
        placeholder: 'Stato*',
        name: 'country',
      },
      label: '',
      errorMessage: 'CAMPO OBBLIGATORIO',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      isDistributer: true,
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Provincia*',
            disabled: true,
          },
        ],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      isDistributer: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Città*',
            disabled: true,
          },
        ],
        placeholder: 'Città',
        name: 'city',
      },
      label: 'Città',
      value: ``,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    wholesaler_id: {
      elementType: 'select',
      isWholesalers: true,
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Nome grossista*',
            disabled: true,
          },
        ],
        placeholder: '',
        name: 'wholesaler_id',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome*',
      },
      label: ``,
      errorMessage: 'CAMPO OBBLIGATORIO',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: 'Cognome*',
      },
      label: ``,
      value: '',
      errorMessage: 'CAMPO OBBLIGATORIO',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Numero di telefono aziendale*',
      },
      label: '',
      value: '',
      errorMessage: 'CAMPO OBBLIGATORIO',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email aziendale*',
      },
      icon: '',
      label: ``,
      errorMessage: 'CAMPO OBBLIGATORIO',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    repeatemail: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'repeatemail',
        id: 'repeatemail',
        placeholder: 'Conferma email*',
      },
      icon: '',
      errorMessage: 'CAMPO OBBLIGATORIO',
      label: ``,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },

    password: {
      elementType: 'input',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password',
      },
      icon: 'password',
      label: '',
      hintPwd:
        'La password deve essere composta da minimo 8 caratteri, una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&)',
      value: '',
      showPwd: true,
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },

      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: 'Conferma password',
      },
      icon: '',
      label: ``,
      value: '',
      showPwd: true,
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      valid: false,
    },

    accetta_regolamento: {
      elementType: 'inputcheckbox',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento',
        id: 'accetta_regolamento',
      },
      label: `<p>Dichiaro di aver letto integralmente e di accettare il  <a href="https://abbpremiplus.it/app/MODIFICA_Regolamento_OP_ABB_Premi_Plus_2024_2025.pdf" target="_blank">Regolamento</a> e l’<a href="https://abbpremiplus.it/app/privacy-policy.html" target="_blank">Informativa Privacy* dell'operazione a premi ABB Premi Plus 2024/2025</a></p>`,
      value: 0,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    accetta_regolamento_concorso_25: {
      elementType: 'inputcheckbox',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'accetta_regolamento_concorso_25',
        id: 'accetta_regolamento_concorso_25',
      },
      errorMessage: 'CAMPO OBBLIGATORIO',
      label: `<p>Dichiaro di aver letto integralmente e di accettare il <a href="https://abbpremiplus.it/app/2025_CONCORSO_ABB_2025_Installatori_e_Banconisti.pdf" target="_blank"><strong>Regolamento del concorso ABB Premi Plus 2025</strong></a>.</p>`,
      value: 0,
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
    gdpr_marketing: {
      elementType: 'inputcheckbox',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'checkbox',
        placeholder: '',
        name: 'gdpr_marketing',
        id: 'gdpr_marketing',
      },
      label: `<p>Dichiaro di prestare il consenso al trattamento dei miei dati personali per le finalità di invio tramite e-mail di comunicazioni commerciali e/o di materiale pubblicitario, relativo a prodotti e/o servizi</p>`,
      value: '',
      validation: {
        required: false,
        touched: false,
      },
      valid: true,
    },
  };
};

export const insertCodePlusForm = ({ item } = {}) => {
  return {
    plus_code: {
      elementType: 'input',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'text',
        name: 'plus_code',
        id: 'plus_code',
        placeholder: `Codice PLUS`,
      },
      label: '',
      value: item?.code || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: item?.code ? true : false,
    },
    nickname: {
      elementType: 'input',
      errorMessage: 'CAMPO OBBLIGATORIO',
      elementConfig: {
        type: 'text',
        name: 'nickname',
        id: 'nickname',
        placeholder: `Nickname`,
      },
      label: '',
      value: item?.name || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: item?.name ? true : false,
    },
  };
};

export const recoveryPasswordForm = (emailLabel, ref) => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Inserisci la tua email',
        ref,
      },
      icon: '',
      label: `${emailLabel}`,
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const changePasswordForm = (
  newPswLabel,
  confirmNewPsw,
  pswerror,
  pswconfirmerror,
  pswwrong,
  ref,
) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Password attuale',
        ref,
      },
      value: '',
      validation: {
        isPassword: true,
        required: true,
        touched: false,
      },
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: `Nuova password`,
        ref,
      },
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'repeatpassword',
        id: 'repeatpassword',
        placeholder: `Ripeti la nuova password`,
      },
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const resetPasswordForm = (newPswLabel, confirmNewPsw, pswerror, pswconfirmerror, ref) => {
  return {
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'password',
        id: 'password',
        placeholder: 'Scegli una nuova password',
        ref,
      },
      label: `${newPswLabel}`,
      value: '',
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    confirmpassword: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        name: 'confirmpassword',
        id: 'confirmpassword',
        placeholder: 'Ripeti la password',
      },
      label: `${confirmNewPsw}`,
      value: '',
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
  };
};

export const uploadReceiptForm = (dateLabel, numberLabel, amountLabel, timeLabel, ref) => {
  return {
    /*    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
      ref,
    }, */
    /* fileextra: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*',
        placeholder: 'Carica il retro dello scontrino',
        name: 'fileextra',
        id: 'fileextra',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    }, */
    /*     date: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: `${dateLabel}`,
        name: 'date',
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
        maxDate: 'today', //mm-dd-yyyy or today
      },
      valid: false,
    },
    time: {
      elementType: 'inputtime',
      elementConfig: {
        type: 'time',
        name: 'time',
        id: 'time',
        placeholder: `${timeLabel}`,
      },
      label: '',
      value: '',
      valuetosend: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    }, */
    code: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'code',
        id: 'code',
        placeholder: `Codice cartolina`,
      },
      label: '',
      value: '',
      min: '0',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    regions: {
      elementType: 'select',
      isRegions: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona regione',
            disabled: false,
          },
        ],
        placeholder: 'Regione',
        name: 'regions',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona provincia',
            disabled: false,
          },
        ],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: '',
      value: '',
      validation: {
        required: true,
      },
      valid: false,
    },
    pdv: {
      elementType: 'select',
      isPdv: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Punto vendita',
            disabled: false,
          },
        ],
        placeholder: 'Punto vendita',
        name: 'pdv',
      },
      label: '',
      value: '',
      validation: {
        required: true,
      },
      valid: false,
    },

    /*    amount: {
      elementType: 'price',
      elementConfig: {
        type: 'number',
        name: 'amount',
        id: 'amount',
        placeholder: `${amountLabel}`,
      },
      label: '',
      value: '',
      min: '0',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    }, */
  };
};

export const contactForm = () => {
  return {
    topic: {
      elementType: 'select',
      isTopic: true,
      elementConfig: {
        options: [{ value: '', displayValue: `Seleziona argomento`, disabled: true }],
        placeholder: `Seleziona argomento`,
        name: 'topic',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    contactusTopic: {
      elementType: 'select',
      isOrder: true,
      elementConfig: {
        options: [{ value: '', displayValue: `Scegli un ordine`, disabled: true }],
        placeholder: `Scegli un ordine`,
        name: 'contactusTopic',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    message: {
      elementType: 'textarea',
      elementConfig: {
        name: 'message',
        id: 'message',
        placeholder: 'Il tuo messaggio',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const profileform = (user, fields_required) => {
  return {
    /*     firstname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'firstname',
        id: 'firstname',
        placeholder: 'Nome',
      },
      label: '',
      value: user.firstname,
      validation: {
        required: fields_required.find(item => item === 'firstname') ? true : false,
        touched: false,
      },
      valid: user.firstname || !fields_required.find(item => item === 'firstname') ? true : false,
    },
    lastname: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'lastname',
        id: 'lastname',
        placeholder: `Cognome`,
      },
      label: '',
      value: user.lastname,
      validation: {
        required: fields_required.find(item => item === 'lastname') ? true : false,
        touched: false,
      },
      valid: user.lastname || !fields_required.find(item => item === 'lastname') ? true : false,
    }, */
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Email aziendale',
      },
      label: '',
      value: user.email,
      validation: {
        required: fields_required.find(item => item === 'email') ? true : false,
        touched: false,
      },
      valid: user.email || !fields_required.find(item => item === 'email') ? true : false,
    },
    phone: {
      elementType: 'input',
      elementConfig: {
        type: 'number',
        name: 'phone',
        id: 'phone',
        placeholder: 'Telefono/Cellulare aziendale',
      },
      label: '',
      value: user.phone,
      validation: {
        required: fields_required.find(item => item === 'phone') ? true : false,
        touched: false,
      },
      valid: user.phone || !fields_required.find(item => item === 'phone') ? true : false,
    },
    /*  company: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'company',
        id: 'company',
        placeholder: 'Azienda',
      },
      label: '',
      value: user.company,
      validation: {
        required: fields_required.find(item => item === 'company') ? true : false,
        touched: false,
      },
      valid: user.company || !fields_required.find(item => item === 'company') ? true : false,
    },
    company_role: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'company_role',
        id: 'company_role',
        placeholder: 'Ruolo',
      },
      label: '',
      value: user.company_role || '',
      validation: {
        required: fields_required.find(item => item === 'company_role') ? true : false,
        touched: false,
      },
      valid:
        user.company_role || !fields_required.find(item => item === 'company_role') ? true : false,
    },
    dob: {
      elementType: 'datepicker',
      elementConfig: {
        placeholder: 'Data di nascita',
        name: 'dob',
      },
      lang: 'it',
      label: '',
      value: (user.dob ? moment(user.dob).format('DD/MM/YYYY') : '') || '',
      valuetosend: (user.dob ? moment(user.dob).format('L') : '') || '',
      validation: {
        required: fields_required.find(item => item === 'dob') ? true : false,
        touched: false,
      },
      valid: user.dob || !fields_required.find(item => item === 'dob') ? true : false,
    },
    gender: {
      elementType: 'select',
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona sesso',
            disabled: true,
          },
          { value: 'm', displayValue: `Maschio` },
          { value: 'f', displayValue: `Femmina` },
        ],
        placeholder: 'Sesso',
        name: 'gender',
      },
      label: ``,
      value: user.gender || '',
      validation: {
        required: fields_required.find(item => item === 'gender') ? true : false,
        touched: false,
      },
      valid: user.gender || !fields_required.find(item => item === 'gender') ? true : false,
    },
    address: {
      elementType: 'input',
      elementConfig: {
        type: 'text',
        name: 'address',
        id: 'address',
        placeholder: 'Indirizzo',
      },
      label: '',
      value: user.address,
      validation: {
        required: fields_required.find(item => item === 'address') ? true : false,
        touched: false,
      },
      valid: user.address || !fields_required.find(item => item === 'address') ? true : false,
    },
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [{ value: '', displayValue: 'Seleziona provincia', disabled: true }],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: '',
      value: user.district || '',
      validation: {
        required: fields_required.find(item => item === 'district') ? true : false,
        touched: false,
      },
      valid: user.district || !fields_required.find(item => item === 'district') ? true : false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: user.city || '',
            displayValue: user.city || 'Seleziona città',
            disabled: true,
          },
        ],
        name: 'city',
      },
      label: '',
      value: user.city || '',
      validation: {
        required: fields_required.find(item => item === 'city') ? true : false,
        touched: false,
      },
      valid: user.city || !fields_required.find(item => item === 'city') ? true : false,
    },
    zipcode: {
      elementType: 'input',
      isZipcode: true,
      elementConfig: {
        type: 'text',
        name: 'zipcode',
        id: 'zipcode',
        placeholder: 'CAP',
      },
      label: '',
      value: user.zipcode || '',
      validation: {
        required: fields_required.find(item => item === 'zipcode') ? true : false,
        touched: false,
      },
      valid: user.zipcode || !fields_required.find(item => item === 'zipcode') ? true : false,
    }, */
  };
};
////REACT_APP_API_URL=https://api.nextplatform.eu/v1/
export const gameform = () => {
  return {
    district: {
      elementType: 'select',
      isDistrict: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona provincia',
            disabled: true,
          },
        ],
        placeholder: 'Provincia',
        name: 'district',
      },
      label: 'Provincia',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    city: {
      elementType: 'select',
      isCity: true,
      elementConfig: {
        options: [
          {
            value: '',
            displayValue: 'Seleziona città',
            disabled: true,
          },
        ],
        placeholder: 'Città',
        name: 'city',
      },
      label: 'Città',
      value: ``,
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const uploadDDTForm = () => {
  return {
    file: {
      elementType: 'inputfile',
      elementConfig: {
        type: 'file',
        accept: 'image/*, application/pdf',
        placeholder: 'SELEZIONA DOCUMENTO',
        name: 'file',
        id: 'file',
      },
      label: '',
      value: '',
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    },
  };
};

export const checkoutForm = obj => {
  return {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        name: 'email',
        id: 'email',
        placeholder: 'Username',
      },
      icon: '',
      label: '',
      value: obj?.email || '',
      validation: {
        required: true,
        touched: false,
      },
      valid: obj.email ? true : false,
    },
  };
};
